@import 'styles/theme.scss';

.markdown {
  p {
    font-weight: $font-weight-400;
    font-size: 16px;
    line-height: 24px;
    color: $font-color;
    a[href^='tel:'],
    a[href^='mailto:'] {
      text-decoration: underline;
      color: $primary-dark;
      &:visited {
        color: $secondary-main;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  ol,
  ul {
    font-weight: $font-weight-400;
    font-size: 16px;
    line-height: 24px;
    color: $font-color;
  }
}
