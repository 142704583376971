@import 'styles/theme.scss';

.containerMarginL {
  margin: $spacing * 20 0;
  @media (max-width: $md) {
    margin: $spacing * 10 0;
  }
}

.containerMarginM {
  margin: $spacing * 16 0;
  @media (max-width: $md) {
    margin: $spacing * 8 0;
  }
}

.containerMarginS {
  margin: $spacing * 10 0;

  @media (max-width: $md) {
    margin: $spacing * 5 0;
  }
}

.containerPaddingS {
  padding: $spacing * 10 0;

  @media (max-width: $md) {
    padding: $spacing * 5 0;
  }
}

.markdownContent {
  strong {
    font-weight: $font-weight-700;
  }

  h1 {
    font-weight: $font-weight-700;
    font-size: 34px;
    line-height: 44px;
  }

  h2 {
    font-weight: $font-weight-700;
    font-size: 24px;
    line-height: 34px;
  }
  h3 {
    font-weight: $font-weight-700;
    font-size: 20px;
    line-height: 24px;
  }

  h4 {
    font-weight: $font-weight-700;
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-weight: $font-weight-500;
    font-size: 20px;
  }

  h6 {
    font-weight: $font-weight-700;
    font-size: 16px;
  }

  a {
    color: $primary-dark;

    &:hover {
      color: $primary-main;
    }
  }
}
