@import 'styles/theme.scss';

.imageAndLinkTitle {
  display: block;
  text-align: center;
}

.videoPlayer {
  display: block;
  max-width: 608px;
  min-height: 366px;
  margin: 24px auto;
}

