@import 'styles/theme.scss';

.container {
  :global {
    .MuiButton-containedSecondary {
      font-weight: $font-weight-500 !important;
      width: 291px;
      padding: 16px 12px;
      margin-top: 16px;
      @media (max-width: $sm) {
        width: 100%;
      }
    }
    .MuiButton-text {
      padding: 1px;
      margin-top: 6px;
    }
  }

  .hyperLink {
    color: $font-color-darker;
    font-weight: $font-weight-400;
    font-size: 16px;
    line-height: 24px;
  }
}

.Center {
  text-align: center;
}
.Left {
  text-align: left;
}
.Right {
  text-align: right;
}

.button {
  max-width: 290px;
  width: 100%;
  border-radius: 4px;
  font-weight: $font-weight-500 !important;
  font-size: 18px !important;
  line-height: 16px;
  color: $white !important;
}

.MainCTA {
  background-color: $main-cta !important;

  &:hover {
    background-color: $main-cta-dark !important;
  }
}

.linkText {
  color: $white;
  line-height: 16px;
}
