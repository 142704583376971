@import 'styles/theme.scss';

.faqContainer {
  .heading {
    color: $secondary-main;
    font-weight: $font-weight-700;
  }
}

.questionList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: $spacing-m 0;
  gap: $spacing-m;

  .childrenContainer {
    padding: 0px;
  }
}

.entry {
  border-bottom: 1px solid $primary-dark;

  button {
    background-color: transparent;
    color: inherit;
    border: none;
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    text-align: left;

    &:focus-visible {
      .question {
        font-weight: $font-weight-700;
      }
    }
  }
}

.question {
  color: $primary-dark;
  position: relative;
  padding-bottom: $spacing-m;
  padding-right: $spacing-xl;

  &:after {
    $size: 12px;
    content: ' ';
    position: absolute;
    right: $spacing-m;
    top: 0;
    border: 2px solid $primary-dark;
    border-bottom-width: 0;
    border-right-width: 0;
    width: $size;
    height: $size;
    transform: rotate(45deg);
  }
}

.answer {
  font-size: 16px;
  line-height: 24px;

  & p {
    color: $font-color;
    margin: 0;
  }
}

.hide {
  .answer {
    display: none;
  }

  .question:after {
    transform: rotate(-135deg);
  }
}

.show {
  padding-bottom: $spacing-m;
}
