// Colors
// the 'uses' information is indicative, we need it to decide which colours to combine together
// TODO: reduce amount of colors by combining similar ones to 1
// TODO: rename colour variables to follow a convention (TBD together with Design team)

// Pale Blues
$primary-50: #f2faff; // 7 uses
$primary-51: #fcf9fb; // 1 use
$state-primary: #f2f8fd; // 1 use
$primary-background: #f6fafc; // 1 use
$primary-background4: #ebf0f5; // 1 use
$pale-blue2: #eef6fe; // 1 use
$pale-blue1: #d3e3f0; // 1 use
$primary-background5: #9aadbc; // 1 use
$secondary-main2: #7a93a9; // 1 use
$font-color-blue2: #597182; // 1 use
$font-color-blue: #43526d; // 1 use
$secondary-main: #033b61; // 43 uses

// Bright Blues
$category-light-blue: #d7f0ff; // 1 use
$primary-100: #e4f5ff; // 10 uses
$primary-200: #d7f0ff; // 1 use
$bg-light-blue: #c0e8ff; // 1 use
$primary-light: #8fd4fc; // 2 uses
$primary-main: #55adee; // 45 uses
$primary-dark: #278be0; // 117 uses
$primary-dark2: #1d78c9; // 1 use
$secondary-light: #0e6db2; // 14 uses
$main-cta2: #1a3da8; // 1 use
$primary-700: #1579ce; // 1 use

// Greys
$white: #ffff; // 94 uses
$primary-background3: #f5f5f5; // 1 use
$footer-line: #eeeeee; // 1 use
$primary-background8: #e8e6e6; // 2 use
$grey1: #e0e0e0; // 4 uses
$grey2: #d9d9d9; // 2 uses
$grey4: #cccccc; // 1 use
$grey3: #999999; // 5 uses
$grey5: #757575; // 1 use
$primary-background6: #676767; // 1 use
$font-color: #595959; // 51 uses
$font-color-darker: #202020; // 4 uses
$font-color-registration: #2d333a; // 3 use
$primary-background2: #1e1e1e; // 2 uses

// Reds
$error-main2: #ef464c; // 1 use
$error-main: #b00020; // 6 uses
$main-cta: #d7001a; // 9 uses
$main-cta-dark: #c01515; // 4 uses

// Yellows
$bg-light-yellow: #fffbda; // 1 uses
$bg-light-yellow2: #fee7a7; // 1 use
$font-color-brown: #dbc165; // 1 use
$font-color-brown2: #aa8400; // 4 uses

// Greens
$notification-light-green: #eefff8; // 1 use
$notification-dark-green: #4a9b72; // 2 uses

// Gradients
$gradient-background: linear-gradient(180deg, #55adee 0%, #278be0 100%); // 3 uses
$gradient-background2: linear-gradient(267.16deg, #1a3da8 1.1%, #2684ff 100%); // 1 use
$gradient-background3: linear-gradient(180deg, #3a99e6 0%, #2e7fcf 100%); // 1 use
$gradient-background4: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 41.23%,
  rgba(0, 0, 0, 0.62) 73.7%
); // 1 use

// Box shadows and transparent backgrounds
$shadow6: rgba(97, 97, 97, 0.9); // 1 use
$shadow3: rgba(0, 0, 0, 0.05); // 2 uses
$shadow2: rgba(0, 0, 0, 0.07); // 1 use
$shadow1: rgba(0, 0, 0, 0.1); // 3 uses
$shadow4: rgba(0, 0, 0, 0.4); // 1 use
$shadow5: rgba(0, 0, 0, 0.5); // 1 use

// Letter spacings
$letter-spacing-body: 0.5px;
$letter-spacing-heading: 0.64px;

// Min-width breakpoints
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

// Max-width breakpoints
$xs-max: $sm - 1px;
$sm-max: $md - 1px;
$md-max: $lg - 1px;
$lg-max: $xl - 1px;

// Font weights
$font-weight-400: 400; // Regular
$font-weight-500: 500; // Medium
$font-weight-600: 600; // Semi-bold
$font-weight-700: 700; // Bold

// Paddings/margins
$spacing: 8px;
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 40px;
$spacing-xxxl: 48px;
$spacing-4xl: 64px;

// Top header
$header-height-sm: 48px;
$header-height-md: 80px;
