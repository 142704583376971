@import 'styles/theme.scss';

.childrenContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  @media (min-width: $md) {
    width: 924px;
    padding: 0;
  }
}
